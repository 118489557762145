<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('deposit_form_detail.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-divider class="mx-3" vertical />
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('deposit_form_detail.head_list1') }}</v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.member.phone" label="Username" outlined dense readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field label="New Password" outlined dense type="password" readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field label="Line" outlined dense readonly />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.member.member_game" label="Username (member)" outlined dense readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field label="Password (member)" outlined dense type="password" readonly />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('deposit_form_detail.head_list2') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-select v-model="datas.accountbank.bank" :label="$t('bank')" :items="bankItems" item-text="label" outlined
                dense readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.accountbank.bank_account" :label="$t('account_number')" outlined dense
                readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.accountbank.bank_name" :label="$t('account_name')" outlined dense readonly />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.accountbank.amount" :label="$t('deposit_form_detail.amount')" outlined dense
                readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.created_at" :label="$t('deposit_form_detail.deposit')" outlined dense
                readonly />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('deposit_form_detail.head_list3') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.promotion.name" :label="$t('deposit_form_detail.promotion_name')" outlined
                dense readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-if="datas.promotion.format === 'normal_min_deposit'" v-model="datas.promotion.min_deposit"
                :label="$t('deposit_form_detail.deposit_min')" outlined dense readonly />
              <v-text-field v-if="datas.promotion.format !== 'normal_min_deposit'" v-model="datas.promotion.percent"
                :label="$t('deposit_form_detail.bonus_amount')" outlined dense readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-if="datas.promotion.format !== 'normal_min_deposit'" v-model="datas.promotion.max_amount"
                :label="$t('deposit_form_detail.bonus_max')" outlined dense readonly />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.promotion.turn_over" :label="$t('deposit_form_detail.multiply')" outlined dense
                readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.bonus" :label="$t('deposit_form_detail.bonus')" outlined dense readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.amount" :label="$t('deposit_form_detail.add')" outlined dense readonly />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-spacer />
          <v-btn color="warning" @click="$router.push({ name: 'deposit' })">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="px-3">{{ $t('back') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import store from '@/store'

export default {
  data() {
    return {
      breadcrumbsItems: [
        {
          text: this.$t('deposit_form_detail.head2'),
          disabled: false,
          href: '/deposit',
        },
        {
          text: this.$t('deposit_form_detail.head3'),
          disabled: true,
          href: '',
        },
      ],
      statusItems: [
        {
          text: this.$t('super_admin'),
          value: 'superAdmin',
        },
        {
          text: this.$t('admin'),
          value: 'admin',
        },
        {
          text: this.$t('member'),
          value: 'member',
        },
      ],
      acceptItems: [
        {
          text: this.$t('yes'),
          value: 'yes',
        },
        {
          text: this.$t('not'),
          value: 'no',
        },
      ],
      bankItems: [],
      underUse: [],
      id: this.$route.params.id,
      datas: {
        id: 0,
        amount: 0,
        bonus: 0,
        status: '',
        member: {
          id: 0,
          phone: null,
          name: null,
          member_game: null,
        },
        accountbank: {
          id: 0,
          bank: '',
          bank_account: '',
          bank_name: '',
        },
        promotion: {
          id: 0,
          type: '',
          name: '',
          turn_over: 0,
          percent: 0,
          max_amount: 0,
        },
        created_at: '',
      },
      datas_member_phone: null,
    }
  },
  async created() {
    this.addLogPage()
    try {
      this.bankItems = await this.$store.dispatch('getTypeBankData')
      this.data = await this.$store.dispatch('getDeposit', {
        id: this.id,
        type: 'success',
        page: 1,
        rows: 1,
      })
      let res = this.data.data[0]
      // this.datas_member_phone = this.data.data[0].member.phone
      this.datas = {
        id: res.id,
        amount: res.amount,
        bonus: res.bonus,
        status: res.status,
        member: {
          id: res.member.id,
          phone: res.member.phone,
          name: res.member.name,
          member_game: res.member.member_game ? res.member.member_game : null,
        },
        accountbank: {
          id: res.accountbank ? res.accountbank.id : null,
          bank: res.accountbank ? res.accountbank.bank : null,
          bank_account: res.accountbank ? res.accountbank.bank_account : null,
          bank_name: res.accountbank ? res.accountbank.bank_name : null,
        },
        promotion: {
          id: res.promotion ? res.promotion.id : null,
          type: res.promotion ? res.promotion.type : null,
          name: res.promotion ? res.promotion.name : null,
          turn_over: res.promotion ? res.promotion.turn_over : null,
          percent: res.promotion ? res.promotion.percent : null,
          max_amount: res.promotion ? res.promotion.max_amount : null,
          format: res.promotion ? res.promotion.format : null,
          min_deposit: res.promotion ? res.promotion.min_deposit : null,
        },
        created_at: moment(res.created_at).format('YY-MM-DD'),
      }
    } catch (e) {
      if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        this.$swal.fire({
          icon: 'error',
          title: e.message,
        })
      }
    }
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'รายการเครดิต',
            url: window.location.href,
            detail: 'ระบบจัดการรายการเครดิต',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
  },
}
</script>
